import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    bot: true,
  })

  const [statusPopup, setStatusPopup] = useState(false)
  const [outputPopup, setOutcomePopup] = useState(true)
  const [messagePopup, setMessagePopup] = useState("")

  const handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    state[name] = value
    setState(state)
  }

  const clearFields = () => {
    setState({
      name: "",
      email: "",
      subject: "",
      message: "",
      bot: true,
    })
  }

  const setFade = () => {
    setTimeout(() => {
      setStatusPopup(false)
    }, 2000)
  }
  const handleSubmit = event => {
    event.preventDefault()
    let formReset = event.target
    fetch("/api.php?mode=email", {
      method: "POST",
      body: JSON.stringify(state),
    })
      .then(response => {
        response.json().then(data => {
          if (data["status"]) {
            setOutcomePopup(true)
            setMessagePopup("Message has been sent.")
            formReset.reset()
            clearFields()
          } else {
            setOutcomePopup(false)
            setMessagePopup("Something went wrong, please try later")
          }
          setStatusPopup(true)
          setFade()
        })
      })
      .then(data => {})
      .catch(error => {
        setOutcomePopup(false)
        setMessagePopup("Something went wrong, please try later")
        setStatusPopup(true)
        setFade()
      })
  }

  return (
    <Layout>
      <SEO title="Contact" description="Drop a line to get in touch" />
      <div className="basic-breadcrumb-area gray-bg ptb-70">
        <div className="container">
          <div className="basic-breadcrumb text-center">
            <h3 className="">Contact Us</h3>
            <ol className="breadcrumb text-xs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">Contact</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="basic-contact-form ptb-90">
        <div className="container">
          <div className="area-title text-center">
            <h2>Let’s talk</h2>
            <p>
              Do you have something in mind or do you need help? Drop me a line.
            </p>
          </div>
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
              <form id="contact-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label className="sr-only">First Name</label>
                    <input
                      type="text"
                      className="form-control input-lg"
                      name="name"
                      placeholder="First Name"
                      onChange={handleInputChange}
                    />
                    <p style={{ className: "help-block text-danger" }}></p>
                  </div>
                  <div className="col-md-6 form-group">
                    <label className="sr-only">Email</label>
                    <input
                      type="email"
                      className="form-control input-lg"
                      name="email"
                      placeholder="Email"
                      onChange={handleInputChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="col-md-12 form-group">
                    <label className="sr-only">Subject</label>
                    <input
                      type="text"
                      className="form-control input-lg"
                      name="subject"
                      placeholder="Subject"
                      onChange={handleInputChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="col-md-12 form-group">
                    <textarea
                      className="form-control input-lg"
                      rows="7"
                      name="message"
                      placeholder="Message*"
                      onChange={handleInputChange}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="col-md-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-lg btn-round btn-dark"
                    >
                      Send Email
                    </button>
                  </div>
                </div>
              </form>
              {statusPopup ? (
                <div
                  className={
                    "ajax-response text-center " +
                    (outputPopup ? "contact-good" : "contact-failed")
                  }
                >
                  {messagePopup}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
